<template>
    <!--编辑页与详情页相同-->
    <div class="MemberDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table border style="width: 100%" :data="tableData" v-loading="tableDataLoading">
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="姓名" prop="name" />
                <el-table-column label="手机号" width="120" prop="mobile" />
                <el-table-column label="性别" width="70" prop="sex">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.sex == null ? '' : scope.row.sex == 0 ? '男' : '女' }}</span></template
                    >
                </el-table-column>
                <el-table-column label="年龄" width="80" prop="age" />
                <el-table-column label="生日" width="180" prop="birthday">
                    <template slot-scope="scope">
                        <el-date-picker
                            v-model="scope.row.birthday"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择日期"
                            readonly
                        />
                    </template>
                </el-table-column>
                <el-table-column label="身份证号" width="200" prop="idNumber" />
                <el-table-column label="推荐人" width="140" prop="developerName" />
                <el-table-column label="省份" width="120" prop="provinceName" />
                <el-table-column label="地市" width="120" prop="cityName" />
                <el-table-column label="区县" width="120" prop="districtName" />
                <el-table-column label="详细地址" width="200" prop="address" />
                <el-table-column label="所属机构" width="200" prop="belongDeptName" />
                <el-table-column label="注册机构" width="200" prop="regDeptName" />
                <el-table-column label="注册渠道" width="140" prop="regChannelName" />
            </el-table>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table border :data="tableDataDetail" v-loading="tableDataDetailLoading">
                <el-table-column label="会员卡号" width="240" prop="cardNo" />
                <el-table-column label="余额" width="240" prop="totalMoney">
                    <template slot-scope="scope"
                        ><span>{{ (scope.row.totalMoney / 10000).toFixed(2) }}</span></template
                    >
                </el-table-column>
                <el-table-column label="积分" width="120" prop="totalPoint" />
                <el-table-column label="发卡机构" width="200" prop="deliverDeptName" />
                <el-table-column label="机构组名称" width="200" prop="deptGroupName" />
            </el-table>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';

export default {
    name: 'MemberDetail',
    props: ['form'],
    data() {
        return {
            url: {
                page: '/member/memberAllDetail/',
            },
            codes: [],
            tableData: [],
            tableDataDetail: [],
            tableDataLoading: true,
            tableDataDetailLoading: false,
        };
    },
    mounted() {
        this.handleQuery();
    },
    methods: {
        handleQuery() {
            const _this = this;

            UrlUtils.QueryRemote(_this, _this.url.page + _this.form.code, (data) => {
                if (data.regDeptType == 1) {
                    data.regDeptType = '总部';
                } else if (data.regDeptType == 2) {
                    data.regDeptType = '配送中心';
                } else {
                    data.regDeptType = '门店';
                }
                data.birthday = data.birthday ? this.formatDate(new Date(data.birthday)) : null;
                this.tableData.push(data);
                this.tableDataDetail = data.extraParamList;
                if (this.tableData.length) {
                    setTimeout(() => {
                        this.tableDataLoading = false;
                    }, 350);
                } else {
                    setTimeout(() => {
                        this.tableDataLoading = false;
                    }, 3000);
                }
                if (this.tableDataDetail.length) {
                    setTimeout(() => {
                        this.tableDataDetailLoading = false;
                    }, 350);
                    this.tableDataDetailLoading = false;
                } else {
                    setTimeout(() => {
                        this.tableDataDetailLoading = false;
                    }, 3000);
                }
            });
        },
    },
    filters: {
        regDeptType(regDeptType) {
            if (typeof regDeptType == 'undefined') {
                return '';
            }
            if (regDeptType == '3') {
                return '门店';
            }
            if (regDeptType == '2') {
                return '配送中心';
            }
            if (regDeptType == '1') {
                return '总部';
            }
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
